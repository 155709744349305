import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import useLocalStorage from "use-local-storage";
import "./Main.scss";
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";

const Main = () => {
  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [theme, setTheme] = useLocalStorage(
    "theme",
    defaultDark ? "dark" : "light"
  );

  const switchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
  };

  return (
    <div className="main" data-theme={theme}>
      <div className="mid-section">
        <div className="header">
          <button className="switcher" onClick={switchTheme}>
            {theme === "light" ? (
              <FontAwesomeIcon size={"2x"} icon={faMoon} className="icon" />
            ) : (
              <FontAwesomeIcon size={"2x"} icon={faSun} className="icon" />
            )}
          </button>
          {/* <button className="home-button">Home</button> */}
        </div>
        <div className="section">
          <div className="section-title">
            <p>About</p>
          </div>
          <div className="section-text">
            <p>{"Hi, I'm Shehjad."}</p>
            <p>
              {"I deploy discretionary + systematic equity derivative trading strategies at "}
              <a
                className="styled-link undisclosed hoverable"
                href="https://www.shjdkhn.xyz/"
                target="_blank"
                rel="noreferrer"
              >
                {"[undisclosed]"}
              </a>
              {" after studying Mathematics and Computer Science at the "}
              <a
                className="styled-link waterloo hoverable"
                href="https://uwaterloo.ca"
                target="_blank"
                rel="noreferrer"
              >
                {"University of Waterloo"}
              </a>
              {". In the past, I've interned as a SWE/quant at "}
              <a
                className="styled-link microsoft hoverable"
                href="https://www.microsoft.com/"
                target="_blank"
                rel="noreferrer"
              >
                {"Microsoft"}
              </a>
              {", "}
              <a
                className="styled-link goldmansachs hoverable"
                href="https://www.goldmansachs.com/"
                target="_blank"
                rel="noreferrer"
              >
                {"Goldman Sachs"}
              </a>
              {", "}
              <a
                className="styled-link mercari hoverable"
                href="https://www.mercari.com/"
                target="_blank"
                rel="noreferrer"
              >
                {"Mercari"}
              </a>
              {", and "}
              <a
                className="styled-link rbc hoverable"
                href="https://www.rbccm.com/en/"
                target="_blank"
                rel="noreferrer"
              >
                {"RBC Capital Markets"}
              </a>
              {"."}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
